<template>
    <div class="grid justify-items-center">
        <img 
            src="../assets/images/furnitures/text-image.jpg"
            class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-80 sm:w-96"
        />
        <div class="mx-5 sm:mx-16 md:mx-20 grid grid-cols-1 sm:grid-cols-2 items-center gap-5 sm:gap-7">
            <img src="../assets/images/furnitures/1.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/2.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/3.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/4.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/5.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/6.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/7.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/8.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/9.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/10.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/11.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/12.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/13.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/14.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/15.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/16.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/17.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/18.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/19.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
            <img src="../assets/images/furnitures/20.jpg" class="rounded-lg shadow-xl mb-4" alt="" />
        </div>
    </div>
</template>